<template>
    <div id="locale-changer" class="" style="height: 38px;" v-if="languages.length > 1">
        <template v-if="languages.length === 2">
            <span :style="$vuetify.breakpoint.smAndUp ? 'width: 40px;height: 30px;': 'width: 30px;height: 30px;'" :class="$i18n.locale === 'en' ? 'fi fi-gb' : 'fi fi-'+$i18n.locale" v-on:click="changeLanguage(languages[0] === $i18n.locale ? languages[1] : languages[0])"></span>
        </template>
        <template v-else>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <span v-on="on" style="width: 40px;height: 30px;":class="$i18n.locale === 'en' ? 'fi fi-gb' : 'fi fi-'+$i18n.locale"></span>
                </template>
                <v-list>
                    <v-list-item v-for="(lang, index) in availableLanguages" :key="index"
                                 @click="changeLanguage(lang)">
                        <v-list-item-icon class="mr-4">
                            <span style="width: 40px;height: 30px;" :class="lang === 'en' ? 'fi fi-gb' : 'fi fi-'+lang"></span>
                        </v-list-item-icon>
<!--                        <v-list-item-title>{{ $t('languages.' + lang) }}</v-list-item-title>-->
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </div>
</template>

<script>
import Storage from "../services/Storage";
import moment from "moment";
import AppStore from "../stores/AppStore";
import axios from "axios";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default {
    name: 'locale-changer',

    mounted() {
        this.languages = AppStore.data.languages;
        this.availableLanguages = this.languages.filter(l => l !== this.$i18n.locale);
        this.changeLanguage(this.languages[0])
    },

    props: {
        reload: {
            type: [Boolean],
            default: true
        },
    },

    data() {
        return {
            languages: [],
            availableLanguages: [],
            AppStore
        }
    },

    methods: {
        changeLanguage(lang) {
            this.$i18n.locale = lang;
            AppStore.locale = lang;
            moment.locale(lang)
            Storage.set('language', lang);
            axios.defaults.headers.common['Accept-Language'] = lang
            document.querySelector('html').setAttribute('lang', lang)
            this.availableLanguages = _.filter(this.languages, function (l) {
                return l !== lang
            });
            // if(this.reload){
            //     window.location.reload()
            // }
        },
    }
}
</script>